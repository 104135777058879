var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { attrs: { header: "true", "header-tag": "header" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("p", { staticClass: "mb-0 d-inline-block align-middle" }, [
            _c("b", [_vm._v(_vm._s(_vm.header))]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.fields, function (field, index) {
        return _c("b-row", { key: index }, [
          _c("div", { staticClass: "col-md-4 border-bottom" }, [
            _vm._v("\n      " + _vm._s(field.label) + "\n    "),
          ]),
          _vm._v(" "),
          field.value === "deferred_status"
            ? _c("div", { staticClass: "col-md-8 border-bottom" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.totalDeferred() === 0 ? "CHIUSO" : "APERTO") +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          field.value === "gross"
            ? _c("div", { staticClass: "col-md-8 border-bottom" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.toLocaleCurrency(_vm.data.gross)) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          field.value === "deferred_pos"
            ? _c("div", { staticClass: "col-md-8 border-bottom" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.toLocaleCurrency(_vm.totalDeferredPos())) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          field.value === "deferred_neg"
            ? _c("div", { staticClass: "col-md-8 border-bottom" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.toLocaleCurrency(
                        -(_vm.totalDeferredNeg() + _vm.toRebate())
                      )
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          field.value === "rebate"
            ? _c("div", { staticClass: "col-md-8 border-bottom" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.toLocaleCurrency(_vm.toRebate())) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          field.value === "total_gross"
            ? _c("div", { staticClass: "col-md-8 border-bottom" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.toLocaleCurrency(_vm.totalDeferred())) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
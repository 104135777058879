var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mt-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onAddBookEntry("brokers")
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v(" Crea Acconto\n        Produttore"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onAddBookEntry("registries")
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v(" Crea Acconto\n        Cliente"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "", id: "collapse-1" } },
            [
              _c(
                "b-form",
                {
                  staticClass: "mt-1",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch(_vm.filterName)
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "deposit",
                                  label: "Mostra Acconti",
                                  options: _vm.deposit,
                                },
                                model: {
                                  value: _vm.filter.byDeposited,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "byDeposited", $$v)
                                  },
                                  expression: "filter.byDeposited",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "salesman_id",
                                  label: "Produttori",
                                  multiple: true,
                                  options: _vm.salesmen,
                                },
                                model: {
                                  value: _vm.filter.byBroker.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter.byBroker, "id", $$v)
                                  },
                                  expression: "filter.byBroker.id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button-toolbar",
                                  [
                                    _c(
                                      "b-button-group",
                                      [
                                        _c("base-input", {
                                          attrs: {
                                            name: "Contraente",
                                            label: "Contraente",
                                            readonly: true,
                                          },
                                          model: {
                                            value: _vm.form.inpt_label_registry,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "inpt_label_registry",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.inpt_label_registry",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-4 btn-quick",
                                            attrs: {
                                              size: "sm",
                                              text: "Button",
                                              variant: "lisaweb",
                                              title: "Ricerca Veloce",
                                            },
                                            on: {
                                              click:
                                                _vm.openQuickSearchRegistry,
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "search" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.form.inpt_label_registry
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mt-4 btn-quick ml-1",
                                                attrs: {
                                                  size: "sm",
                                                  text: "Button",
                                                  variant: "lisaweb",
                                                  title: "Elimina Anagrafica",
                                                },
                                                on: {
                                                  click: _vm.resetRegistryId,
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("quick-search-registry-modal", {
                            ref: "quickSearchRegistry",
                            on: { input: _vm.handleRegistryModalInput },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _vm.registry_data
                              ? _c("span", {
                                  staticClass: "info",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.toInfoData(
                                        _vm.registry_data,
                                        "registry"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button-toolbar",
                                  [
                                    _c(
                                      "b-button-group",
                                      [
                                        _c("base-input", {
                                          attrs: {
                                            name: "Codice Gruppo",
                                            label: "Codice Gruppo",
                                            readonly: true,
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .input_label_registry_group,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "input_label_registry_group",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.input_label_registry_group",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-4 btn-quick",
                                            attrs: {
                                              size: "sm",
                                              text: "Button",
                                              variant: "lisaweb",
                                              title: "Ricerca Veloce",
                                            },
                                            on: {
                                              click:
                                                _vm.openQuickSearchRegistryGroup,
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "search" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.form.input_label_registry_group
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mt-4 btn-quick ml-1",
                                                attrs: {
                                                  size: "sm",
                                                  text: "Button",
                                                  variant: "lisaweb",
                                                  title: "Elimina Gruppo",
                                                },
                                                on: {
                                                  click:
                                                    _vm.resetRegistryGroupId,
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("quick-search-registry-group-modal", {
                            ref: "quickSearchRegistryGroup",
                            on: { input: _vm.handleRegistryGroupModalInput },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "aperto_dal",
                                label: "Periodo di registrazione acconto dal",
                              },
                              model: {
                                value: _vm.filter.byCalendar.from,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "from", $$v)
                                },
                                expression: "filter.byCalendar.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "aperto_al",
                                label: "Periodo di registrazione acconto al",
                              },
                              model: {
                                value: _vm.filter.byCalendar.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "to", $$v)
                                },
                                expression: "filter.byCalendar.to",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "incasso_successivo",
                                label: "Periodo di recupero acconto dal",
                              },
                              model: {
                                value: _vm.filter.byReduced.after_recovery,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byReduced,
                                    "after_recovery",
                                    $$v
                                  )
                                },
                                expression: "filter.byReduced.after_recovery",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "incasso_precedente",
                                label: "Periodo di recupero acconto al",
                              },
                              model: {
                                value: _vm.filter.byReduced.before_recovery,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byReduced,
                                    "before_recovery",
                                    $$v
                                  )
                                },
                                expression: "filter.byReduced.before_recovery",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-1",
                                    arg: "collapse-1",
                                  },
                                ],
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter(_vm.filterName)
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("book-entries-deposited", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              filterOn: {
                byRelations: [
                  "byVariousAccounting",
                  "byRegistry",
                  "byBroker",
                  "byTask",
                  "byDocument",
                ],
              },
              resource: _vm.resource,
              filterName: _vm.filterName,
              onlyActions: ["edit", "destroy", "infomodal"],
              descending: "",
              sortField: "book_date",
            },
            on: {
              open_note: _vm.openNoteModal,
              open_document: _vm.openDocumentModal,
              destroy: _vm.onDestroy,
              edit: _vm.onEdit,
            },
          }),
          _vm._v(" "),
          _c("note-detail-modal", { attrs: { items: _vm.notes } }),
          _vm._v(" "),
          _c("document-detail-modal", { attrs: { items: _vm.documents } }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
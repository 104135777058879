<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
          :tbody-tr-class="rowClass"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(last_deferred)="{ item }" v-if="items.length">
            {{
              checkLastDeferredDate(item.entry_details)
                ? moment(checkLastDeferredDate(item.entry_details)).format(
                    "DD/MM/YYYY"
                  )
                : ""
            }}
          </template>
          <template #cell(branch)="{ item }" v-if="items.length">
            {{
              item.insurance_ancillaries && item.insurance_ancillaries.length
                ? item.insurance_ancillaries[0].insurance_policy
                  ? item.insurance_ancillaries[0].insurance_policy
                      .insurance_risk
                    ? item.insurance_ancillaries[0].insurance_policy
                        .insurance_risk.risk_branch.formatted_title
                    : ""
                  : ""
                : item.various_accountings && item.various_accountings.length
                ? item.various_accountings[0].pivot.insurance_risk
                  ? item.various_accountings[0].pivot.insurance_risk.risk_branch
                      .formatted_title
                  : ""
                : ""
            }}
          </template>
          <template #cell(product)="{ item }" v-if="items.length">
            {{
              item.insurance_ancillaries && item.insurance_ancillaries.length
                ? item.insurance_ancillaries[0].insurance_policy
                  ? item.insurance_ancillaries[0].insurance_policy
                      .insurance_risk.formatted_title
                  : ""
                : item.various_accountings && item.various_accountings.length
                ? item.various_accountings[0].pivot.insurance_risk
                  ? item.various_accountings[0].pivot.insurance_risk
                      .formatted_title
                  : ""
                : ""
            }}
          </template>
          <template
            #cell(insurance_policy_number)="{ item }"
            v-if="items.length"
          >
            {{
              item.various_accountings && item.various_accountings.length
                ? item.various_accountings[0].pivot.insurance_policy_number
                : item.insurance_ancillaries &&
                  item.insurance_ancillaries.length
                ? item.insurance_ancillaries[0].insurance_policy
                  ? item.insurance_ancillaries[0].insurance_policy.number
                  : ""
                : ""
            }}
          </template>
          <template #cell(note_counter)="{ item }" v-if="items.length">
            <div
              :role="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? 'button'
                  : null
              "
              class="info-box float-left"
              @click="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? openNoteDetailModal(
                      item.tasks.filter((task) => task.task_type.value === 2)
                    )
                  : null
              "
              v-b-tooltip="
                toTooltipTask(
                  item.tasks.filter((task) => task.task_type.value === 2),
                  'Note'
                )
              "
            >
              <!-- :title="
                  item.tasks.filter((task) => task.task_type.value === 2).length
                    ? 'Mostra Note'
                    : null
                " -->
              {{
                item.tasks.filter((task) => task.task_type.value === 2).length
              }}
            </div>
          </template>
          <template #cell(registry)="{ item }" v-if="items.length">
            <div v-for="(registry, index) in item.registries" :key="index">
              <span
                v-if="
                  !Array.isArray(registry.attributables) &&
                  !registry.attributables.length
                "
                >{{
                  registry.status_registry.value === 1
                    ? registry.attributables.CNAM
                    : registry.attributables.SURN +
                      " " +
                      registry.attributables.NAME
                }}</span
              >
            </div>
          </template>
          <template #cell(deferred)="{ item }" v-if="items.length">
            {{ toLocaleCurrency(totalDeferred(item)) }}
          </template>
          <template #cell(broker)="{ item }" v-if="items.length">
            <span v-html="toInfoData(item.brokers[0], 'broker', 0)"></span>
          </template>
          <template #cell(note)="{ item }" v-if="items.length">
            {{ item.tasks.length }}
          </template>
          <template
            v-slot:head(check)="data"
            v-if="hasChecks && !isCheckExclusive && items.length"
          >
            <base-checkbox
              v-model="checkAll"
              name="all"
              @change="onSelectAllRows"
              ><span v-if="data.label" v-html="data.label"></span>
            </base-checkbox>
          </template>
          <template #cell(check)="row" v-if="hasChecks">
            <base-checkbox
              name="check"
              v-model="row.item.check"
              @input="onSelectRow($event, row.index, row.item)"
            />
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-row class="table-extra-footer" v-if="hasChecks">
        <div class="deferred_yes_colour"></div>
        <p class="label">Aperti</p>
        <div class="deferred_no_colour"></div>
        <p class="label">Parzialmente recuperati</p>
        <div class="deferred_reduced_colour"></div>
        <p class="label">Chiusi</p>
        <p class="label">Righe Selezionate: {{ getSelectedRowsLength() }}</p>
        <p class="label">
          Totale da Incassare: {{ toLocaleCurrency(grandTotalDeferred) }}
        </p>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import BaseInputPagination from "@/components/form/BaseInputPagination";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import General from "@/components/accounting/deferred/details/General.vue";
import Deferred from "@/components/accounting/deferred/details/Deferred.vue";
import Appointments from "@/components/accounting/deferred/details/Appointments";
import Notes from "@/components/accounting/deferred/details/Notes";
import Todo from "@/components/accounting/deferred/details/Todo";
import { toLocaleCurrency } from "@/utils/strings";
import moment from "moment";
moment.locale("it");

export default {
  name: "BookEntriesDeferredTable",
  extends: template,
  data() {
    return {
      tabs: [
        { name: "General", text: "Recupero Sospeso" },
        { name: "Deferred", text: "Storico Recupero" },
        { name: "Appointments", text: "Appuntamenti" },
        { name: "Notes", text: "Note" },
        { name: "Todo", text: "Attività" },
      ],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  components: {
    BaseIcon,
    General,
    Deferred,
    ButtonGroupTab,
    BaseCheckbox,
    Todo,
    Notes,
    Appointments,
    BaseInputPagination,
  },
  methods: {
    moment,
    toLocaleCurrency,
    openNoteDetailModal(array) {
      this.$emit("open_note", array);
    },
    getSelectedRowsLength() {
      if (this.selectedRows.length) {
        return this.selectedRows.length;
      }
      return 0;
    },
    totalDeferred(item) {
      return parseFloat(
        item.entry_details
          .filter((el) => el?.treasury?.code == "SS")
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    checkLastDeferredDate(entry_details) {
      let filteredEntryDetails = entry_details.filter(
        (entry_detail) =>
          entry_detail?.treasury?.code === "SS" && entry_detail.gross < 0
      );
      if (filteredEntryDetails.length) {
        let lastDeferredDate = "1900-01-01";
        for (const entry_detail of filteredEntryDetails) {
          if (moment(entry_detail.detail_date).isAfter(lastDeferredDate)) {
            lastDeferredDate = entry_detail.detail_date;
          }
        }
        return lastDeferredDate;
      }
      return "";
    },
    customized(item) {
      let c = {};
      c.titolo = item.title;
      c.data_movimento = item.book_date;
      c.lordo = toLocaleCurrency(this.totalDeferred(item));
      return c;
    },
    rowClass(item) {
      if (item) {
        if (this.totalDeferred(item) === 0) return "deferred-status-2";
        if (
          item.entry_details.find(
            (entry_detail) =>
              entry_detail?.treasury?.code === "SS" && entry_detail.gross < 0
          )
        )
          return "deferred-status-1";
      }
      return "deferred-status-0";
    },
  },
  computed: {
    grandTotalDeferred() {
      // per ogni Righe Selezionate, calcolo totalDeferred e sommo
      let ret = 0;
      if (this.selectedRows.length) {
        ret = this.items
          .filter((e) => this.selectedRows.includes(e.id))
          .reduce(
            (previousValue, currentValue) =>
              previousValue + this.totalDeferred(currentValue),
            ret
          );
      }
      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.check) {
  width: 1px;
  // padding-left: 10px;
}
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
/** inserisce un gradiente orizzontale nella prima cella della table */
/* ROSSO */
:deep(.deferred-status-0 td:nth-child(3)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(228, 33, 33, 0.3) 100%
  );
}
/* VERDE */
:deep(.deferred-status-1 td:nth-child(3)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(19, 172, 19, 0.4) 100%
  );
}
/* NEUTRO */
:deep(.deferred-status-2 td:nth-child(3)) {
  background: rgb(255, 255, 255);
}
.label {
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.deferred_yes_colour {
  width: 15px;
  height: 15px;
  background: rgba(228, 33, 33, 0.3);
  margin-left: 18px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.deferred_no_colour {
  width: 15px;
  height: 15px;
  background: rgba(19, 172, 19, 0.4);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.deferred_reduced_colour {
  width: 15px;
  height: 15px;
  background: rgba(255, 255, 255, 1);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mt-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-create mt-1 mr-1",
                      attrs: {
                        size: "sm",
                        variant: "primary",
                        title: "Chiusura Massiva",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openMassReducedModal()
                        },
                      },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "plus-circle", "aria-hidden": "true" },
                      }),
                      _vm._v("\n        Chiusura Massiva\n      "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "", id: "collapse-1" } },
            [
              _c(
                "b-form",
                {
                  staticClass: "mt-1",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch(_vm.filterName)
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "deferred",
                                label: "Mostra Sospesi",
                                options: _vm.deferred,
                              },
                              model: {
                                value: _vm.filter.byDeferred,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "byDeferred", $$v)
                                },
                                expression: "filter.byDeferred",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "salesman_id",
                                label: "Produttori",
                                options: _vm.salesmen,
                              },
                              model: {
                                value: _vm.filter.byBroker.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byBroker, "id", $$v)
                                },
                                expression: "filter.byBroker.id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button-toolbar",
                                  [
                                    _c(
                                      "b-button-group",
                                      [
                                        _c("base-input", {
                                          attrs: {
                                            name: "Contraente",
                                            label: "Contraente",
                                            readonly: true,
                                          },
                                          model: {
                                            value: _vm.form.inpt_label_registry,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "inpt_label_registry",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.inpt_label_registry",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-4 btn-quick",
                                            attrs: {
                                              size: "sm",
                                              text: "Button",
                                              variant: "lisaweb",
                                              title: "Ricerca Veloce",
                                            },
                                            on: {
                                              click:
                                                _vm.openQuickSearchRegistry,
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "search" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.form.inpt_label_registry
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mt-4 btn-quick ml-1",
                                                attrs: {
                                                  size: "sm",
                                                  text: "Button",
                                                  variant: "lisaweb",
                                                  title: "Elimina Anagrafica",
                                                },
                                                on: {
                                                  click: _vm.resetRegistryId,
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("quick-search-registry-modal", {
                            ref: "quickSearchRegistry",
                            on: { input: _vm.handleRegistryModalInput },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _vm.registry_data
                              ? _c("span", {
                                  staticClass: "info",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.toInfoData(
                                        _vm.registry_data,
                                        "registry"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button-toolbar",
                                  [
                                    _c(
                                      "b-button-group",
                                      [
                                        _c("base-input", {
                                          attrs: {
                                            name: "Polizza",
                                            label: "Polizza",
                                            readonly: true,
                                          },
                                          model: {
                                            value: _vm.form.inpt_label_policy,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "inpt_label_policy",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.inpt_label_policy",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-4 btn-quick",
                                            attrs: {
                                              size: "sm",
                                              text: "Button",
                                              variant: "lisaweb",
                                              title: "Ricerca Veloce",
                                            },
                                            on: {
                                              click: _vm.openQuickSearchPolicy,
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "search" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.form.inpt_label_policy
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mt-4 btn-quick ml-1",
                                                attrs: {
                                                  size: "sm",
                                                  text: "Button",
                                                  variant: "lisaweb",
                                                  title: "Elimina Polizza",
                                                },
                                                on: {
                                                  click: _vm.resetPolicyId,
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("quick-search-policy-modal", {
                            ref: "quickSearchPolicy",
                            on: { input: _vm.handlePolicyModalInput },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _vm.insurance_policy_data
                              ? _c("span", {
                                  staticClass: "info",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.toInfoData(
                                        _vm.insurance_policy_data,
                                        "insurance_policy",
                                        0
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "aperto_dal",
                                label:
                                  "Periodo di registrazione a cassa del sospeso dal",
                              },
                              model: {
                                value: _vm.filter.byCalendar.from,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "from", $$v)
                                },
                                expression: "filter.byCalendar.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "aperto_al",
                                label:
                                  "Periodo di registrazione a cassa del sospeso al",
                              },
                              model: {
                                value: _vm.filter.byCalendar.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "to", $$v)
                                },
                                expression: "filter.byCalendar.to",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "incasso_successivo",
                                label: "Periodo di recupero del sospeso dal",
                              },
                              model: {
                                value: _vm.filter.byReduced.after_deferred,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byReduced,
                                    "after_deferred",
                                    $$v
                                  )
                                },
                                expression: "filter.byReduced.after_deferred",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "incasso_precedente",
                                label: "Periodo di recupero del sospeso al",
                              },
                              model: {
                                value: _vm.filter.byReduced.before_deferred,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byReduced,
                                    "before_deferred",
                                    $$v
                                  )
                                },
                                expression: "filter.byReduced.before_deferred",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "insurer",
                                  label: "Compagnia",
                                  options: _vm.companies,
                                },
                                model: {
                                  value: _vm.filter.byInsurer.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter.byInsurer, "id", $$v)
                                  },
                                  expression: "filter.byInsurer.id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "code",
                                  label: "Modalità rec. sospeso",
                                  options: _vm.treasuries,
                                  multiple: true,
                                },
                                model: {
                                  value: _vm.filter.byTreasury.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter.byTreasury, "id", $$v)
                                  },
                                  expression: "filter.byTreasury.id",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button-toolbar",
                                  [
                                    _c(
                                      "b-button-group",
                                      [
                                        _c("base-input", {
                                          attrs: {
                                            name: "Codice Gruppo",
                                            label: "Codice Gruppo",
                                            readonly: true,
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .input_label_registry_group,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "input_label_registry_group",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.input_label_registry_group",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-4 btn-quick",
                                            attrs: {
                                              size: "sm",
                                              text: "Button",
                                              variant: "lisaweb",
                                              title: "Ricerca Veloce",
                                            },
                                            on: {
                                              click:
                                                _vm.openQuickSearchRegistryGroup,
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "search" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.form.input_label_registry_group
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mt-4 btn-quick ml-1",
                                                attrs: {
                                                  size: "sm",
                                                  text: "Button",
                                                  variant: "lisaweb",
                                                  title: "Elimina Gruppo",
                                                },
                                                on: {
                                                  click:
                                                    _vm.resetRegistryGroupId,
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("quick-search-registry-group-modal", {
                            ref: "quickSearchRegistryGroup",
                            on: { input: _vm.handleRegistryGroupModalInput },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-1",
                                    arg: "collapse-1",
                                  },
                                ],
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter(_vm.filterName)
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-button-group",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:collapse-1-inner",
                              arg: "collapse-1-inner",
                            },
                          ],
                          staticClass: "my-2 filter-button-group",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "when-open" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "when-closed" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v("\n          Altri Filtri\n        "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "mt-2",
                          attrs: { id: "collapse-1-inner" },
                        },
                        [
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "Note",
                                    label: "Note",
                                    placeholder: "Inserisci una nota",
                                  },
                                  model: {
                                    value:
                                      _vm.filter.byCustom.description.value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byCustom.description,
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filter.byCustom.description.value",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "code",
                                    label: "Delega",
                                    options: [
                                      { value: "0", text: "Delega Nostra" },
                                      { value: "1", text: "Delega Altrui" },
                                    ],
                                  },
                                  model: {
                                    value:
                                      _vm.filter.byInsurancePolicy.sharing_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byInsurancePolicy,
                                        "sharing_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filter.byInsurancePolicy.sharing_type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.customFilters.length > 0,
                                  expression: "customFilters.length > 0",
                                },
                              ],
                            },
                            [
                              _c("p", [_vm._v("Attributi personalizzati")]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.customFilters,
                                function (element, index) {
                                  return _c(
                                    "b-row",
                                    { key: index },
                                    [
                                      _c("custom-filter", {
                                        attrs: { element: element },
                                        model: {
                                          value:
                                            _vm.filter.byAttribute[element.key],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filter.byAttribute,
                                              element.key,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filter.byAttribute[element.key]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("export-options", {
            attrs: {
              exportUrl: _vm.exportUrl,
              repository: _vm.repository,
              resource: _vm.resource,
              tableRef: _vm.$refs[_vm.tableRef],
              filter: _vm.filter,
              options: [
                {
                  code: "LISTSOSP",
                  label: null,
                  formats: ["csv", "pdf"],
                },
                {
                  code: "TOTASOSP",
                  label: null,
                  formats: ["csv", "pdf"],
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("book-entries-deferred", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              filterOn: {
                byRelations: [
                  "byVariousAccounting",
                  "byRegistry",
                  "byInsurer",
                  "byBroker",
                  "byInsurancePolicy",
                  "byTask",
                ],
              },
              resource: _vm.resource,
              filterName: _vm.filterName,
              onlyActions: ["infomodal"],
              hasChecksButtonGroup: false,
              hasChecks: "",
              descending: "",
              sortField: "book_date",
            },
            on: {
              open_note: _vm.openNoteModal,
              onMassReduced: _vm.openMassReducedModal,
            },
          }),
          _vm._v(" "),
          _c("mass-reduced-modal", {
            attrs: { grandtotaldeferred: _vm.grandtotaldeferred },
            on: { save: _vm.storeMassReduced },
          }),
          _vm._v(" "),
          _c("note-detail-modal", { attrs: { items: _vm.notes } }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }